import { Dropdown, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { localStorageService } from "~/services/LocalStorage";

import React, { useCallback } from "react";
import ToggleItem from "./lib/ToggleItem";
import { TColumn } from "../Table/lib/types";

export type Props = {
  columns: TColumn[];
  onToggle: (name: string, checked: boolean) => void;
  storageKey: string;
};

type LocalStorageColumn = {
  name: string;
  shouldDisplay: boolean;
};

const ColumnToggler: React.FC<Props> = ({ columns, onToggle, storageKey }) => {
  const hideColumn = (name: string, projection?: boolean): boolean => {
    const columnsToHide = localStorageService.get(
      `${storageKey}Columns`
    ) as LocalStorageColumn[];
    if (columnsToHide) {
      const findColumn = columnsToHide.find((x) => x.name === name);
      return findColumn ? findColumn.shouldDisplay : !!projection;
    }
    return !!projection;
  };

  const formatMessage = useCallback((id: string) => {
    return <FormattedMessage id={`app.${id}`} defaultMessage={id} />;
  }, []);

  const createCheckboxes = (): JSX.Element[] => {
    return columns
      .filter((column) => !column.hide)
      .map((e) => (
        <ToggleItem
          key={`${storageKey}-${e.name}`}
          name={e.name}
          label={formatMessage(e.label)}
          initialChecked={hideColumn(e.name, e.projection)}
          onClick={onToggle}
          storageKey={storageKey}
        />
      ));
  };

  return (
    <Dropdown
      icon={false}
      direction="left"
      simple
      trigger={<Icon name="columns" color="grey" fitted />}
    >
      <Dropdown.Menu
        style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "400px" }}
      >
        <Dropdown.Header>
          <FormattedMessage id="app.columns-menu.header" />
        </Dropdown.Header>
        <Dropdown.Divider />
        {createCheckboxes()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColumnToggler;
