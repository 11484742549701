import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState } from "~/store";
import {
  completeCodedPart,
  fetchCodedParts,
} from "~/store/coded-parts/actions";
import { TCodedPartsProps, TReduxActions, TReduxState } from "./constans";
import ManualOrdersList from "./List";
import { CodedPart, MappedCodedPart } from "~/store/coded-parts/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionaryName } from "~/store/dictionaries/types";
import translations from "~/utils/translations";
import { TColumn } from "~/components/Table/lib/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { useManualOrdersColumns } from "./hooks/useManualOrdersColumns";

const ManualOrders: React.FC<TCodedPartsProps> = ({
  codedPartsList,
  loadingCodedParts,
  meta,
  totalRecords,
  fetchDictionary,
  fetchCodedParts,
  completeCodedPartSuccess,
  loadingCompleteCodedPart,
  match,
  dictionaries,
  completeCodedPart,
}): JSX.Element => {
  const { columnsConfig } = useManualOrdersColumns();
  const [tableKey] = useState<string>("manual-orders");
  const [filterLabels] = useState<any[]>([]);
  const [sortColumn] = useState<any>("ldcOrderDate");
  const [sortDirection] = useState<any>("DESC");
  const [columns, setColumns] = useState(columnsConfig);

  const [list, setList] = useState<MappedCodedPart[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState<Object>({});

  const getManualOrders = (params?: Object): void => {
    const paramsObj = {
      page: meta.page,
      size: meta.size,
      filters: filterLabels,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };
    setParams(paramsObj);
    fetchCodedParts(Object.assign(paramsObj, params));
  };

  useEffect(() => {
    document.title = translations.format("app.manual-orders");
    fetchDictionary(DictionaryName.orderStatus);
  }, [fetchDictionary]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
              label: translations.format(column.label),
            }
          : { ...column, label: translations.format(column.label) }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  useEffect(() => {
    const mapedList = codedPartsList.map((order: CodedPart) => {
      const cuttedOrderNumber = order.id.split("-");
      let lineNumber = cuttedOrderNumber.pop();
      const id = cuttedOrderNumber.join("-");
      const item: MappedCodedPart = {
        ...order,
        id: id,
        lineNumber: lineNumber || "",
      };
      return item;
    });
    setList(mapedList);
  }, [codedPartsList, completeCodedPartSuccess]);

  return (
    <Fragment>
      <PageHeader
        icon="tag"
        title={<FormattedMessage id="app.manual-orders" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[]}
        refreshAction={() => {
          getManualOrders({ ...params, page: 1 });
        }}
        loading={loadingCodedParts}
      />
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loadingCodedParts}
        totalRecords={totalRecords}
        fetchMethod={getManualOrders}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        <ManualOrdersList
          parts={list}
          columns={columns}
          cancelCodedPartReqestPending={false}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          loadingCompleteCodedPart={loadingCompleteCodedPart}
          showDetailsId={match.params.manualOrderId}
          ldcOrderId={match.params.ldcOrderId}
          completeCodedPart={completeCodedPart}
        />
      </DataGridTable>
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  codedParts,
  dictionaries,
}: ApplicationState) => ({ ...codedParts, dictionaries });

const mapDispatchToProps: TReduxActions = {
  fetchCodedParts,
  fetchDictionary,
  completeCodedPart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualOrders);
