import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchMMData,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { ICollectiveMM } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { useInventoryTransferColumns } from "./useInventoryTransferColumns";
import IconHover from "~/components/IconHover/IconHover";
import MMDetails from "../../Details/MMDetails";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useInventoryTransferAdvancedSearch } from "./useInventoryTransferAdvancedSearch";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";

type TComponentProps = {
  refreshTrigger: number;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
};

type TReduxState = {
  mmList: ICollectiveMM[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
};

interface TReduxActions {
  fetchMMData: typeof fetchMMData;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
}

type InventoryTransferProps = TComponentProps & TReduxState & TReduxActions;

const InventoryTransfer: React.FC<InventoryTransferProps> = ({
  fetchMMData,
  mmList,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  openChooseTypeOfExportDataModal,
  setDisableExportButton,
  setPercenExportButton,
}) => {
  const { columnsConfig } = useInventoryTransferColumns();
  const [tableKey] = useState<string>("inventory-transfer");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("issueDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport: undefined,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "MM",
  });

  const getInventoryTransfers = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchMMData(Object.assign(paramsObj, params));
    },
    [
      fetchMMData,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getInventoryTransfers({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getInventoryTransfers]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  useEffect(() => {
    if (isOpenChooseTypeOfExportDataModal) {
      handleOpenDownloadModal();
      openChooseTypeOfExportDataModal(false);
    }
  }, [
    isOpenChooseTypeOfExportDataModal,
    handleOpenDownloadModal,
    openChooseTypeOfExportDataModal,
  ]);

  const { formFields, handleSubmit } = useInventoryTransferAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getInventoryTransfers,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getInventoryTransfers}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {mmList.map((document: ICollectiveMM, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <MMDetails noteId={document.id || ""}>
                <div role="button">
                  <IconHover name="arrow right" title="Podgląd" />
                </div>
              </MMDetails>
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  mmList: invoices.mmDataResponse.list,
  loading: invoices.loadingMM,
  totalRecords: invoices.mmDataResponse.totalRecords,
  meta: invoices.mmDataResponse.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchMMData,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTransfer);
