import { FormattedMessage } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  List,
} from "semantic-ui-react";
import { parseDate } from "~/utils/dateUtils";
import {
  EMAIL,
  LENGTH,
  MAX,
  ONLY_NUMBER_VALUES,
  PHONE,
  REQUIRED,
  SUPPLIER_SYSTEM_PAIR,
} from "~/components/SmartField/lib/validator";
import { ApplicationState } from "~/store";
import {
  fetchCurrentPayer,
  fetchCustomerClasifications,
  fetchPayerHistoryList,
  removeCustomerWarehouse,
  selectCustomer,
} from "~/store/customer/actions";
import {
  CurrentAccountingCustomer,
  Customer,
  OperatingWarehouse,
  SupplierSystem,
} from "~/store/customer/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import {
  DictionariesState,
  DictionaryItem,
  DictionaryName,
} from "~/store/dictionaries/types";
import { TabSwitcher, TCard, TTabName } from "./lib/TabSwitcher";
import { useSelectOptions } from "./lib/useSelectOptions";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import DropdownToggle from "./lib/DropdownToggle";
import "./style.scss";
import { fetchWarehouses } from "~/store/warehouses-in-calendar/actions";
import AddPaymentMethod from "./lib/AddPaymentMethod";
import ActivePaymentForm from "./lib/ActualPaymentMethod";
import AddDistrigoCredit from "./lib/AddDistrigoCredit";
import DistrigoLoanLimit from "./lib/ActualDistrigoCredit";
import Factor from "./lib/Factor";
import { fetchFactors } from "~/store/factors/actions";
import { CustomerPaymentFactor } from "~/store/factors/types";
import { fetchDistrigoCredit } from "~/store/distrigo-credit/actions";
import { fetchPaymentMethods } from "~/store/payment-methods/actions";
import { CustomerPaymentMethod } from "~/store/payment-methods/types";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { CustomerType } from "~/pages/Customers/lib/AddContractor";
import { CardKey } from "~/pages/Customers/Details/constants/cardKey";
import { ChangePayerCard } from "~/pages/Customers/Details/lib/card/ChangePayerCard";
import { CurrentPayerCard } from "~/pages/Customers/Details/lib/card/CurrentPayerCard";
import translations from "~/utils/translations";
import { ClassAndBrandsCard } from "./lib/card/ClassAndBrandsCard";
import { BrandCode } from "./lib/card/BrandCode";
import AdditionalDiscountCodeCard from "./lib/card/AdditionalDiscountCode";

const _ = require("lodash");

const initialWarehouse: OperatingWarehouse = {
  warehouseId: "",
  deliveryType: "",
  deliveryNote: "",
  deliveryPlatform: "",
  deliveryRoute: "",
  deliveryZone: "",
  deliveryGroup: "",
  deliveryTransportContract: "",
};

type TReduxState = {
  customerIn?: Customer;
  dictionaries: DictionariesState;
  loading: boolean;
  factors: CustomerPaymentFactor[];
  creditLoading: boolean;
  creditCreated: boolean;
  createdFactorId: string | null;
  paymentMethods: CustomerPaymentMethod[];
  currentPayer: null | CurrentAccountingCustomer;
  payerHistoryList: CurrentAccountingCustomer[];
  classifications: string[];
};

type TRouteParams = RouteComponentProps<{
  rrdi: string;
  edit?: string;
}>;

type TCustomerDetailsProps = TReduxState & TRouteParams;

const CustomerDetails: React.FC<TCustomerDetailsProps> = ({
  match,
  customerIn,
  dictionaries,
  loading,
  creditLoading,
  createdFactorId,
  factors,
  creditCreated,
  paymentMethods,
  currentPayer,
  payerHistoryList,
  classifications,
}) => {
  const [customer, setCustomer] = useState<Customer>();
  const [factorIds, setFactorIds] = useState<any>([]);
  const [customerPaymentFactors, setCustomerPaymentFactors] = useState<any>([]);
  const [editMode, setEditMode] = useState(!!match.params.edit);
  const [visibleCards, setVisibleCards] = useState<TCard[]>([]);
  const [activeItem, setActiveItem] = useState<TTabName>("main");
  const [classificationsDict, setClassificationsDict] = useState<
    DictionaryItem[]
  >([]);
  const { warehousesList } = useSelector(
    (store: ApplicationState) => store.warehouses_in_calendar
  );
  const [supplierSystem, setSupplierSystem] = useState<SupplierSystem[]>([]);

  const dispatch = useDispatch();

  const appContext = useContext(AppContext);

  const userHaveLdcOrderViewRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW),
    [appContext]
  );

  const userHaveLdcCustomerManagementViewRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CUSTOMER_MANAGEMENT
      ),
    [appContext]
  );

  const userHaveLdcClaimsViewRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW),
    [appContext]
  );

  const userHaveLdcCustomerPayerEditRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CUSTOMER_PAYER_EDIT
      ),
    [appContext]
  );

  const userHaveLdcAccountingViewRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_VIEW
      ),
    [appContext]
  );

  const userHaveLdcUiCustomerEditRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CUSTOMER_EDIT
      ),
    [appContext]
  );

  useEffect(() => {
    dispatch(fetchWarehouses());
    dispatch(fetchCustomerClasifications());
  }, [dispatch]);

  useEffect(() => {
    setClassificationsDict(
      classifications.map((item) => ({ key: item, text: item, value: item }))
    );
  }, [classifications]);

  useEffect(() => {
    document.title = translations.format("app.customers");
  }, [dispatch, customer]);

  useEffect(() => {
    setCustomerPaymentFactors(factors);
  }, [factors, customerPaymentFactors]);

  useEffect(() => {
    if (customer?.rrdi) {
      dispatch(fetchFactors(customer.rrdi));
      setFactorIds([]);
    }
  }, [createdFactorId, customer, dispatch]);

  const warehouseOptions = useMemo(() => {
    if (!customer || !customer.operatingWarehouses) {
      return [];
    }

    const options: any = [];
    let warehouses = [...warehousesList.res];

    customer.operatingWarehouses.forEach((warehouse, index) => {
      options.push([]);
      warehouses.forEach(({ id, name }) => {
        options[index].push({ value: id, text: name });
      });

      warehouses = warehouses.filter(({ id }) => warehouse.warehouseId !== id);
    });

    return options;
  }, [warehousesList, customer]);

  useEffect(() => {
    if (!customer || customer.rrdi !== match.params.rrdi) {
      dispatch(
        fetchDictionary(
          DictionaryName.customerCategoryZone,
          DictionaryName.paymentFactor,
          DictionaryName.paymentMethod,
          DictionaryName.paymentDue,
          DictionaryName.customerCategoryOrganization,
          DictionaryName.customerInformationAddressRegion,
          DictionaryName.customerPaymentCurrency,
          DictionaryName.orderDeliveryType,
          DictionaryName.customerSapClass,
          DictionaryName.transportType,
          DictionaryName.sincoms
        )
      );
      dispatch(selectCustomer(match.params.rrdi));
    }
    // eslint-disable-next-line
  }, [match.params.rrdi, customer, fetchDictionary, selectCustomer]);

  useEffect(() => {
    if (activeItem === "changePayer") {
      dispatch(fetchCurrentPayer(match.params.rrdi));
      dispatch(fetchPayerHistoryList(match.params.rrdi));
    }
  }, [
    match.params.rrdi,
    activeItem,
    dispatch,
    userHaveLdcCustomerPayerEditRole,
  ]);

  useEffect(() => {
    if (customerIn) {
      setCustomer(customerIn);
      setSupplierSystem(customerIn.supplierSystem);
    }
  }, [customerIn]);

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const toggleEditMode = (): void => {
    setTimeout(() => {
      setEditMode(!editMode);
    }, 500);
  };

  const handleAdd = useCallback(() => {
    if (customer) {
      const newCustomer = {
        ...customer,
        operatingWarehouses: customer.operatingWarehouses
          ? [...customer.operatingWarehouses, { ...initialWarehouse }]
          : [{ ...initialWarehouse }],
      };
      setCustomer(newCustomer);
    }
  }, [customer]);

  const handleRemove = useCallback(
    (idx, warehouseId) => {
      if (customer && customer.operatingWarehouses) {
        const newCustomer = {
          ...customer,
          operatingWarehouses: customer.operatingWarehouses.filter(
            (_, index) => index !== idx
          ),
        };
        dispatch(removeCustomerWarehouse(customer.rrdi, warehouseId));
        setCustomer(newCustomer);
      }
    },
    [customer, dispatch]
  );

  const handleAddFactor = useCallback(() => {
    let factorsN = [
      ...factorIds,
      { id: "111" + Math.floor(Math.random() * 10) },
    ];
    setFactorIds(factorsN);
  }, [factorIds]);

  const handleRemoveFactor = useCallback(
    (idx: number) => {
      const factorsNew = factorIds.filter(
        (_: any, index: number) => index !== idx
      );
      setFactorIds(factorsNew);
    },
    [factorIds]
  );

  // eslint-disable-next-line
  const handleSupplierSystemLoadMapper = (
    system: string,
    type: string
  ): string => {
    if (customer && customer.supplierSystem && customer.supplierSystem.length) {
      const findedIndex = customer.supplierSystem.findIndex(
        (sys) => sys.businessSector === system
      );
      if (findedIndex >= 0) {
        return type === "clientId"
          ? customer.supplierSystem[findedIndex].clientId
          : customer.supplierSystem[findedIndex].deliveryId;
      }
    }
    return "";
  };

  // eslint-disable-next-line
  const handleSupplierSystemSaveMapper = (): SupplierSystem[] => {
    setEditMode(!editMode);
    setTimeout(() => {
      setEditMode(true);
    }, 500);
    return supplierSystem;
  };

  const handleUpdate = (model: Customer, response: any): void => {
    setCustomer({ ...model });
  };

  const onBeforeSave = (data: any, data2: any): any => {
    if (data2.name.includes("operatingWarehouses")) {
      let keyName = data2.name.split(".").pop() || data2.name;
      const arrayValue = data2.name.match(/\[(.*?)\]/);
      const index = arrayValue ? arrayValue[1] : 0;
      const warehouseId =
        keyName === "warehouseId"
          ? data2.value
          : _.get(
              data2.latestModel,
              `operatingWarehouses[${index}].warehouseId`
            );

      return {
        operatingWarehouses: [
          {
            [keyName]: data2.value,
            warehouseId,
          },
        ],
      };
    }
    return data;
  };

  const refreshResources = useMemo(
    () => () => {
      dispatch(selectCustomer(match.params.rrdi));
      dispatch(fetchDistrigoCredit(match.params.rrdi));
      dispatch(fetchPaymentMethods(match.params.rrdi));
      dispatch(fetchFactors(match.params.rrdi));
    },
    [dispatch, match.params.rrdi]
  );

  const options = useSelectOptions();
  const {
    renderField,
    renderSelect,
    renderToggle,
    renderTextArea,
    renderLabel,
  } = useRenderingFunctions(customer, editMode);

  const factor: JSX.Element[] = useMemo(() => {
    let existingFactors = [];

    if (customerPaymentFactors) {
      existingFactors =
        customerPaymentFactors?.length !== 0
          ? customerPaymentFactors.map((factor: any, index: number) => (
              <DetailCard
                id={`factor-${index}`}
                title={`Factor ${index + 1}`}
                key={`factor-${index}`}
                width={16}
                titleFormat
              >
                <Factor
                  index={index}
                  editMode={userHaveLdcCustomerManagementViewRole && editMode}
                  handleRemove={handleRemoveFactor}
                  handleAdd={handleAddFactor}
                  customerId={customer?.rrdi || ""}
                  factor={factor}
                  isNewFactor={false}
                  downloadXLSPending={false}
                />
              </DetailCard>
            ))
          : [];
    }

    let newFactor =
      factorIds || factorIds.length
        ? factorIds.map((factor: CustomerPaymentFactor, index: number) => {
            return customer ? (
              <DetailCard
                id={`factor-${index + customerPaymentFactors.length + 1}`}
                title={`Factor ${index + customerPaymentFactors.length + 1}`}
                key={`factor-${index + customerPaymentFactors.length + 1}`}
                width={16}
                titleFormat
              >
                <Factor
                  index={index}
                  editMode={userHaveLdcCustomerManagementViewRole && editMode}
                  handleRemove={handleRemoveFactor}
                  handleAdd={handleAddFactor}
                  customerId={customer.rrdi}
                  factor={factor}
                  isNewFactor={true}
                  downloadXLSPending={false}
                />
              </DetailCard>
            ) : null;
          })
        : [];
    let button =
      !customerPaymentFactors || customerPaymentFactors?.length === 0
        ? [
            <div className="button-container" id="addFactor" key="addFactor">
              <Divider style={{ display: editMode ? "block" : "none" }} />
              <Button
                icon="add"
                size="medium"
                circular
                basic
                style={{ display: editMode ? "inline-block" : "none" }}
                content="Dodaj faktor"
                onClick={handleAddFactor}
              />
            </div>,
          ]
        : [];

    return [...button, ...existingFactors, ...newFactor];
  }, [
    editMode,
    handleAddFactor,
    handleRemoveFactor,
    factorIds,
    customerPaymentFactors,
    customer,
    userHaveLdcCustomerManagementViewRole,
  ]);

  const customerWarehouse: JSX.Element[] = useMemo(() => {
    return !customer ||
      !customer.operatingWarehouses ||
      !customer.operatingWarehouses.length
      ? [
          <div
            className="button-container"
            id="addWarehouse"
            key="addWarehouse"
          >
            <Divider style={{ display: editMode ? "block" : "none" }} />
            <Button
              icon="add"
              size="medium"
              circular
              basic
              style={{ display: editMode ? "inline-block" : "none" }}
              content="Dodaj magazyn"
              onClick={handleAdd}
            />
          </div>,
        ]
      : customer.operatingWarehouses.map((warehouse, index) => {
          return (
            <DetailCard
              id={`warehouse-${index}`}
              title={`Magazyn ${warehouse.warehouseId}`}
              key={`warehouse-${index}`}
              width={16}
              titleFormat
            >
              <div className="ui stretched grid">
                <DetailCard
                  title="app.name"
                  id={warehouse.warehouseId}
                  key={warehouse.warehouseId}
                  width={4}
                >
                  {renderSelect(
                    `operatingWarehouses[${index}].warehouseId`,
                    warehouseOptions[index],
                    {
                      label: formatMessage("app.distrigoWarehouse"),
                      description: formatMessage(
                        "app.customer.warehouse.popup1"
                      ),
                      disabled:
                        customer.operatingWarehouses &&
                        !!customer.operatingWarehouses[index].warehouseId,
                    }
                  )}
                  {renderSelect(
                    `operatingWarehouses[${index}].deliveryTransportContract`,
                    dictionaries["transport-type"],
                    {
                      label: formatMessage("app.transportContract"),
                      description: formatMessage(
                        "app.customer.warehouse.popup2"
                      ),
                    }
                  )}
                </DetailCard>

                <DetailCard
                  title="app.delivery"
                  id={`delivery-${index}`}
                  key={`delivery-${index}`}
                  width={4}
                >
                  {renderSelect(
                    `operatingWarehouses[${index}].deliveryType`,
                    dictionaries["order-delivery-type"],
                    {
                      label: formatMessage("app.deliveryType"),
                    }
                  )}
                  {renderTextArea(
                    `operatingWarehouses[${index}].deliveryNote`,
                    {
                      label: formatMessage("app.comments"),
                      description: formatMessage(
                        "app.customer.delivery.popup1"
                      ),
                    }
                  )}
                </DetailCard>

                <DetailCard
                  title="app.transport"
                  id={`transport-${index}`}
                  key={`transport-${index}`}
                  width={4}
                >
                  {renderField(
                    `operatingWarehouses[${index}].deliveryPlatform`,
                    {
                      label: formatMessage("app.customer.transport.platform"),
                      description: formatMessage(
                        "app.customer.transport.popup1"
                      ),
                    }
                  )}
                  {renderField(`operatingWarehouses[${index}].deliveryRoute`, {
                    label: formatMessage("app.customer.transport.route"),
                    description: formatMessage("app.customer.transport.popup2"),
                  })}
                  {renderField(`operatingWarehouses[${index}].deliveryZone`, {
                    label: formatMessage("app.customer.transport.zone"),
                    description: formatMessage("app.customer.transport.popup3"),
                  })}
                  {renderField(`operatingWarehouses[${index}].deliveryGroup`, {
                    label: formatMessage("app.customer.transport.group"),
                    description: formatMessage("app.customer.transport.popup4"),
                  })}
                </DetailCard>
                <div className="button-container">
                  <Divider style={{ display: editMode ? "block" : "none" }} />
                  <Button
                    icon="add"
                    size="medium"
                    circular
                    basic
                    style={{
                      display:
                        editMode &&
                        customer.operatingWarehouses?.length === index + 1 &&
                        index < warehousesList.res.length - 1
                          ? "inline-block"
                          : "none",
                    }}
                    content="Dodaj magazyn"
                    onClick={handleAdd}
                  />
                  <Button
                    icon="remove"
                    size="medium"
                    circular
                    basic
                    style={{ display: editMode ? "inline-block" : "none" }}
                    content="Usuń magazyn"
                    onClick={() => handleRemove(index, warehouse.warehouseId)}
                  />
                </div>
              </div>
            </DetailCard>
          );
        });
  }, [
    customer,
    dictionaries,
    renderField,
    renderSelect,
    renderTextArea,
    warehouseOptions,
    editMode,
    handleAdd,
    handleRemove,
    warehousesList.res.length,
  ]);

  const cards = useMemo(
    () =>
      !customer
        ? []
        : [
            <ChangePayerCard
              id={CardKey.changePayer}
              key={CardKey.changePayer}
              editMode={editMode}
              customerId={customer.rrdi}
            />,
            <CurrentPayerCard
              id={CardKey.payerDetails}
              key={CardKey.payerDetails}
              currentPayer={currentPayer}
              payerHistoryList={payerHistoryList}
            />,
            <DetailCard
              title="app.identification"
              id={CardKey.identification}
              key={CardKey.identification}
            >
              {renderSelect("customerType", options.customerTypes, {
                label: "Typ kontrahenta",
                validators: [REQUIRED],
              })}
              <Form.Group widths="equal">
                {renderField("rrdi", {
                  label: formatMessage("app.customer.clientCode"),
                  description: formatMessage("app.customer.clientCode"),
                  validators: [REQUIRED, LENGTH(7, 9)],
                })}
                {renderField("clientId", {
                  label: formatMessage("app.paymentPerson"),
                  description: formatMessage("app.paymentPerson"),
                  disabled: customerIn?.customerType === "PAYER",
                  validators: [LENGTH(7)],
                })}
              </Form.Group>
              <Form.Group widths="equal">
                {renderField("sapDeliveryId", {
                  label: formatMessage("app.customer.sapDeliveryId"),
                  description: formatMessage("app.customer.sapDeliveryId"),
                  validators: [REQUIRED, LENGTH(9)],
                })}
                {renderField("sapClientId", {
                  label: formatMessage("app.customer.sapClientId"),
                  description: formatMessage("app.customer.sapClientId"),
                  validators: [REQUIRED, LENGTH(7)],
                })}
              </Form.Group>
              <Form.Group widths="equal">
                {customer.ddsDeliveryId &&
                  renderField("ddsDeliveryId", {
                    label: formatMessage("app.customer.ovDeliveryId"),
                    description: formatMessage("app.customer.ovDeliveryId"),
                    validators: [REQUIRED, LENGTH(9)],
                    disabled: true,
                  })}
                {customer.ddsDeliveryId &&
                  renderField("ddsClientId", {
                    label: formatMessage("app.customer.ovClientId"),
                    description: formatMessage("app.customer.ovClientId"),
                    validators: [REQUIRED, LENGTH(9)],
                    disabled: true,
                  })}
              </Form.Group>

              <Divider></Divider>

              <Form.Group widths="equal">
                {renderField("supplierSystem", {
                  label: formatMessage("app.customer.FLHAP-Recipient"),
                  description: formatMessage("app.customer.FLHAP-Recipient"),

                  validators: [
                    LENGTH(4),
                    ONLY_NUMBER_VALUES(
                      new RegExp("^[0-9]*$"),
                      handleSupplierSystemLoadMapper("FLHAP", "deliveryId")
                    ),
                    SUPPLIER_SYSTEM_PAIR(
                      supplierSystem,
                      setSupplierSystem,
                      "FLHAP",
                      "deliveryId"
                    ),
                  ],
                  loadMapper: () =>
                    handleSupplierSystemLoadMapper("FLHAP", "deliveryId"),
                  saveMapper: (value) => handleSupplierSystemSaveMapper(),
                })}
                {renderSelect(
                  "supplierSystem",
                  // @ts-ignore
                  dictionaries.sincoms["FLHAP"],
                  {
                    label: formatMessage("app.customer.FLHAP-Payer"),
                    description: formatMessage("app.customer.FLHAP-Payer"),

                    validators: [
                      SUPPLIER_SYSTEM_PAIR(
                        supplierSystem,
                        setSupplierSystem,
                        "FLHAP",
                        "clientId"
                      ),
                    ],
                    loadMapper: () =>
                      handleSupplierSystemLoadMapper("FLHAP", "clientId"),
                    saveMapper: (value) => handleSupplierSystemSaveMapper(),
                  }
                )}
              </Form.Group>
              <Form.Group widths="equal">
                {renderField("supplierSystem", {
                  label: formatMessage("app.customer.CJD-Recipient"),
                  description: formatMessage("app.customer.CJD-Recipient"),

                  validators: [
                    LENGTH(4),
                    ONLY_NUMBER_VALUES(
                      new RegExp("^[0-9]*$"),
                      handleSupplierSystemLoadMapper("CJD", "deliveryId")
                    ),
                    SUPPLIER_SYSTEM_PAIR(
                      supplierSystem,
                      setSupplierSystem,
                      "CJD",
                      "deliveryId"
                    ),
                  ],
                  loadMapper: () =>
                    handleSupplierSystemLoadMapper("CJD", "deliveryId"),
                  saveMapper: (value) => handleSupplierSystemSaveMapper(),
                })}
                {
                  // @ts-ignore
                  renderSelect("supplierSystem", dictionaries.sincoms["CJD"], {
                    label: formatMessage("app.customer.CJD-Payer"),
                    description: formatMessage("app.customer.CJD-Payer"),

                    validators: [
                      SUPPLIER_SYSTEM_PAIR(
                        supplierSystem,
                        setSupplierSystem,
                        "CJD",
                        "clientId"
                      ),
                    ],
                    loadMapper: () =>
                      handleSupplierSystemLoadMapper("CJD", "clientId"),
                    saveMapper: (value) => handleSupplierSystemSaveMapper(),
                  })
                }
              </Form.Group>
            </DetailCard>,
            <DetailCard
              title="app.personality"
              id={CardKey.personality}
              key={CardKey.personality}
            >
              {renderSelect("categoryCompany", options.company, {
                label: formatMessage("app.customer.categoryCompany"),
                description: formatMessage("app.customer.personality.popup1"),
              })}
              {renderField("informationAddressCompany", {
                label: formatMessage("app.name"),
                description: formatMessage("app.customer.personality.popup2"),
                validators: [MAX(100)],
              })}
              <Form.Group widths="equal">
                {renderField("informationContactName", {
                  label: formatMessage("app.customer.informationContactName"),
                  description: formatMessage("app.customer.personality.popup3"),
                  validators: [MAX(20)],
                })}
                {renderField("informationContactSurname", {
                  label: formatMessage(
                    "app.customer.informationContactSurname"
                  ),
                  description: formatMessage("app.customer.personality.popup4"),
                  validators: [MAX(50)],
                })}
              </Form.Group>
              {renderSelect("informationGender", options.gender, {
                label: formatMessage("app.gender"),
                description: formatMessage("app.customer.personality.popup5"),
              })}
            </DetailCard>,
            customer.customerType !== CustomerType.PAYER && (
              <DetailCard
                title="app.classesAndBrands"
                id={CardKey.classesAndBrands}
                key={CardKey.classesAndBrands}
                style={{ position: "relative" }}
              >
                <ClassAndBrandsCard editMode={editMode} customer={customer} />
              </DetailCard>
            ),
            customer.customerType !== CustomerType.PAYER && (
              <DetailCard
                title="app.brandCode"
                id={CardKey.brandsCodes}
                key={CardKey.brandsCodes}
              >
                <BrandCode brands={customer?.customerBrands} />
              </DetailCard>
            ),
            <DetailCard
              title="app.characteristic"
              id={CardKey.characteristic}
              key={CardKey.characteristic}
            >
              {renderSelect("categoryPp", options.unity, {
                label: formatMessage("app.PPunity"),
                description: formatMessage(
                  "app.customer.characteristic.popup3"
                ),
                validators: [REQUIRED],
              })}
              {renderSelect(
                "categoryZone",
                dictionaries["customer-category-zone"],
                {
                  label: formatMessage("app.zoneRegion"),
                  description: formatMessage(
                    "app.customer.characteristic.popup4"
                  ),
                }
              )}
              {customer.customerType === CustomerType.PAYER ||
              customer.orderType === "STOCK"
                ? null
                : renderSelect(
                    "sapCustomerClass",
                    dictionaries["customer-sap-class"],
                    {
                      label: formatMessage("app.customer.sapCustomerClass"),
                      description: formatMessage(
                        "app.customer.sapCustomerClassDetails"
                      ),
                    }
                  )}
              {customer.customerType === CustomerType.PAYER ||
              customer.orderType === "STOCK"
                ? null
                : renderField("sapRrdiCode", {
                    label: formatMessage("app.customer.sapRrdiCode"),
                    description: formatMessage(
                      "app.customer.sapRrdiCodeDetails"
                    ),
                  })}
              {customer.customerType === CustomerType.PAYER &&
                renderSelect("classification", classificationsDict, {
                  label: formatMessage("app.customer.classifications"),
                  description: formatMessage(
                    "app.customer.characteristic.popup1"
                  ),
                  validators: [REQUIRED],
                })}
            </DetailCard>,
            <DetailCard
              title="app.contact"
              id={CardKey.contact}
              key={CardKey.contact}
            >
              {renderField("informationContactEmail", {
                label: formatMessage("app.customer.informationContactEmail"),
                description: formatMessage("app.customer.contact.popup1"),
                validators: [EMAIL, REQUIRED],
              })}
              {renderField("informationContactHomePhone", {
                label: formatMessage(
                  "app.customer.informationContactHomePhone"
                ),
                description: formatMessage("app.customer.contact.popup2"),
                validators: [PHONE, REQUIRED],
              })}
              {renderField("informationContactMobilePhone", {
                label: formatMessage(
                  "app.customer.informationContactMobilePhone"
                ),
                description: formatMessage("app.customer.contact.popup3"),
                validators: [PHONE],
              })}
            </DetailCard>,
            <DetailCard
              title="app.address"
              id={CardKey.address}
              key={CardKey.address}
            >
              <Form.Group>
                {renderField("informationAddressStreet", {
                  label: formatMessage("app.customer.informationAddressStreet"),
                  description: formatMessage("app.customer.address.popup2"),
                  width: 12,
                  validators: [REQUIRED],
                })}
                {renderField("informationAddressNumber", {
                  label: formatMessage("app.number"),
                  description: formatMessage("app.customer.address.popup3"),
                  width: 4,
                  validators: [REQUIRED],
                })}
              </Form.Group>
              <Form.Group>
                {renderField("informationAddressZipcode", {
                  label: formatMessage(
                    "app.customer.informationAddressZipcode"
                  ),
                  description: formatMessage("app.customer.address.popup4"),
                  width: 6,
                  validators: [REQUIRED],
                })}
                {renderField("informationAddressCity", {
                  label: formatMessage("app.customer.informationAddressCity"),
                  description: formatMessage("app.customer.address.popup5"),
                  width: 10,
                  validators: [REQUIRED],
                })}
              </Form.Group>
              {renderSelect(
                "informationAddressRegion",
                dictionaries["customer-information-address-region"],
                {
                  label: formatMessage("app.customer.voivodeship"),
                  description: formatMessage("app.customer.address.popup1"),
                  validators: [REQUIRED],
                }
              )}
            </DetailCard>, //faktor
            ...factor, // kredyt distirgo

            <DetailCard
              title="app.customer.distrigoLoanLimit"
              id={CardKey.distrigoLoanLimit}
              key={CardKey.distrigoLoanLimit}
            >
              <DistrigoLoanLimit
                customerId={customer && customer.rrdi}
                editMode={userHaveLdcCustomerManagementViewRole && editMode}
              />
            </DetailCard>,
            <DetailCard
              title="app.customer.addNewLoan"
              id={CardKey.addNewLoan}
              key={CardKey.addNewLoan}
            >
              <AddDistrigoCredit
                customerId={customer && customer.rrdi}
                editMode={userHaveLdcCustomerManagementViewRole && editMode}
                dictionaries={dictionaries}
                loading={creditLoading}
                created={creditCreated}
              />
            </DetailCard>,
            <DetailCard
              title="app.customer.loan.rules"
              id={CardKey.creditRules}
              key={CardKey.creditRules}
            >
              <p>
                W formularzu przydzielenia kredytu Distrigo widnieje suwak o
                nazwie <b>Odnawialny </b>
                odblokowywujący pole <b>Okres</b>, w którym widnieją dostępne
                okresy odnawialności. <br />
                <br />W przypadku, gdy użytkownik wybierze jeden z nich oraz
                zatwierdzi formularz, natychmiast zostaną wyczyszczone
                obciążenia związane z fakturami danego kontrahenta. Następnie w
                zależności od wybranego okresu odnawialności system będzie
                cyklicznie powtarzał ten proces począwszy od pierwszego dnia
                danego okresu, tak jak poniżej:
                <List>
                  <List.Item>
                    <b>Tygodniowy</b>: pierwszy dzień kolejnego tygodnia -
                    poniedziałek
                  </List.Item>
                  <List.Item>
                    <b>Miesięczny</b>: pierwszy dzień kolejnego miesiąca
                  </List.Item>
                  <List.Item>
                    <b>Kwartalny</b>: pierwszy dzień kolejnego kwartału
                  </List.Item>
                </List>
                <b style={{ textDecoration: "underline" }}>Uwaga!</b>
                <br />
                <b>
                  System przy każdym dodaniu kolejnego kredytu Distrigo
                  jednorazowo czyści obciążenia faktur danego kontrahenta.
                </b>
                <br />
                <br />
                <b style={{ textDecoration: "underline" }}>Przykład</b>
                <p>
                  W dniu 05.05.2022 (czwartek) danemu kontrahentowi został
                  przydzielony nowy kredyt Distrigo z odnawialnością tygodniową.
                  W takim przypadku system wyczyści obciążenia kontrahenta
                  związane z fakturami w kolejny poniedziałek czyli 09.05.2022.
                </p>
              </p>
            </DetailCard>,

            <DetailCard
              title="app.customer.activePaymentForm"
              id={CardKey.activePaymentForm}
              key={CardKey.activePaymentForm}
            >
              <ActivePaymentForm customer={customer} editMode={editMode} />
            </DetailCard>,

            <DetailCard
              title="app.customer.addPaymentForm"
              id={CardKey.addPaymentForm}
              key={CardKey.addPaymentForm}
            >
              <AddPaymentMethod
                customerId={customer && customer.rrdi}
                editMode={editMode}
                dictionaries={dictionaries}
                refreshResources={refreshResources}
              />
            </DetailCard>,
            ...customerWarehouse,
            <DetailCard
              title="app.adnotation"
              id={CardKey.adnotation}
              key={CardKey.adnotation}
            >
              {renderField("categorySector", {
                label: formatMessage("app.customer.other.sector"),
                description: formatMessage("app.customer.other.popup1"),
              })}
              {renderTextArea("notes", {
                label: formatMessage("app.customer.other.notes"),
                description: formatMessage("app.customer.other.popup2"),
              })}
            </DetailCard>,
            customer.customerType === CustomerType.DELIVERY && (
              <DetailCard
                title="app.additionalOrderDiscount"
                id={CardKey.additionalDiscountCode}
                key={CardKey.additionalDiscountCode}
              >
                <AdditionalDiscountCodeCard
                  customer={customer}
                  editMode={editMode}
                />
              </DetailCard>
            ),
            <DetailCard
              title="app.paymentPerson"
              id={CardKey.paymentPerson}
              key={CardKey.paymentPerson}
            >
              {renderToggle("accountingVatActive", {
                label: formatMessage("app.customer.accounting.activeVAT"),
                description: formatMessage("app.customer.accounting.popup1"),
              })}
              {renderField("accountingVatId", {
                label: formatMessage("app.customer.accounting.nip"),
                description: formatMessage("app.customer.accounting.popup2"),
              })}
              {renderField("accountingInvoiceSigner", {
                label: formatMessage(
                  "app.customer.accounting.invoiceReceiving"
                ),
                description: formatMessage("app.customer.accounting.popup3"),
              })}
              {renderSelect(
                "paymentCurrency",
                dictionaries["customer-payment-currency"],
                {
                  label: formatMessage("app.customer.accounting.currency"),
                  description: formatMessage("app.customer.accounting.popup4"),
                }
              )}
            </DetailCard>,
            <DetailCard
              title="app.docsDistribution"
              id={CardKey.docsDistribution}
              key={CardKey.docsDistribution}
            >
              {customer.customerType === CustomerType.PAYER &&
                renderToggle("accountingTransmissionEmailActive", {
                  label: formatMessage("app.customer.docsDistribution.emailOn"),
                  description: formatMessage(
                    "app.customer.docsDistribution.popup1"
                  ),
                })}
              {customer.customerType === CustomerType.PAYER &&
                renderField("accountingTransmissionEmail", {
                  label: formatMessage("app.customer.docsDistribution.email"),
                  description: formatMessage(
                    "app.customer.docsDistribution.popup2"
                  ),
                  validators: [EMAIL],
                  disabled: !customer.accountingTransmissionEmailActive,
                })}
              <Divider />
              {renderToggle("accountingTransmissionDmsActive", {
                label: formatMessage("app.customer.docsDistribution.dms"),
                description: formatMessage(
                  "app.customer.docsDistribution.popup3"
                ),
              })}
              {renderField("accountingTransmissionDms", {
                label: formatMessage("app.customer.docsDistribution.dmsId"),
                description: formatMessage(
                  "app.customer.docsDistribution.popup4"
                ),
                disabled: !customer.accountingTransmissionDmsActive,
              })}
              <Divider />
              {renderToggle("accountingTransmissionGuiActive", {
                label: formatMessage("app.customer.docsDistribution.userPanel"),
                description: formatMessage(
                  "app.customer.docsDistribution.popup5"
                ),
              })}
            </DetailCard>,
            <DetailCard title="app.bank" id={CardKey.bank} key={CardKey.bank}>
              {renderField("paymentBankAccount", {
                label: formatMessage("app.customer.bank.bankAcc"),
                description: formatMessage("app.customer.bank.popup1"),
                validators: [MAX(28)],
              })}
              {renderField("paymentFactor", {
                label: "Nr umowy",
                description: "Numer umowy",
              })}
            </DetailCard>,
            <DetailCard
              title="app.permissions"
              id={CardKey.permissions}
              key={CardKey.permissions}
            >
              {renderToggle("gdprMessageAccepted", {
                label: formatMessage("app.customer.gpdr.permission"),
                description: formatMessage("app.customer.gpdr.popup1"),
              })}
              {renderLabel(
                formatMessage("app.customer.gpdr.permissionDate"),
                parseDate(customer.gdprMessageDate)
              )}
            </DetailCard>,
          ],
    [
      customer,
      editMode,
      currentPayer,
      payerHistoryList,
      renderSelect,
      options.customerTypes,
      options.company,
      options.gender,
      options.unity,
      renderField,
      customerIn?.customerType,
      dictionaries,
      factor,
      userHaveLdcCustomerManagementViewRole,
      creditLoading,
      creditCreated,
      customerWarehouse,
      supplierSystem,
      renderTextArea,
      renderToggle,
      renderLabel,
      handleSupplierSystemLoadMapper,
      handleSupplierSystemSaveMapper,
      classificationsDict,
      refreshResources,
    ]
  );

  const cardsRendered: JSX.Element = useMemo(() => {
    const result = cards.filter(
      (card) => visibleCards.indexOf(card?.props?.id) !== -1
    );
    return (
      <div className="uber-content">
        <Grid stretched>{result}</Grid>
      </div>
    );
  }, [visibleCards, cards]);

  const blockMenuRendered: JSX.Element | undefined = useMemo(() => {
    if (!customer) {
      return;
    }
    return (
      <Dropdown.Menu>
        <DropdownToggle
          icon="ban"
          label={<FormattedMessage id="app.dropdown.userBlock" />}
          name="blocked"
          checked={customer.blocked}
          onMessage="Zablokowano użytkownika."
          offMessage="Odblokowano użytkownika."
          errorMessage="Błąd podczas zmiany blokady użytkownika."
          disabled={
            (!customer.currentPaymentMethod ||
              customer.currentPaymentMethod?.type === "UNDEFINED") &&
            customer.blocked
          }
        />
        <DropdownToggle
          icon="dollar sign"
          label={<FormattedMessage id="app.dropdown.limitBlock" />}
          name="paymentCreditBlocked"
          checked={customer.paymentCreditBlocked}
          onMessage="Zablokowano limit kredytowy."
          offMessage="Odblokowano limit kredytowy."
          errorMessage="Wystąpił błąd podczas zmiany blokady limitu kredytowego."
        />
      </Dropdown.Menu>
    );
  }, [customer]);

  const renderedCustomerTitle: JSX.Element | string = useMemo(() => {
    if (!customer) {
      return "Wczytywanie...";
    }
    if (customer.categoryCompany) {
      return (
        <Fragment>
          {customer.rrdi} <small>{customer.informationAddressCompany}</small>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {customer.rrdi}{" "}
        <small>
          {customer.informationContactName} {customer.informationContactSurname}
        </small>
      </Fragment>
    );
  }, [customer]);

  return (
    <Fragment>
      <SmartWrapper
        endpoint={customer ? `/customers/${customer.rrdi}` : ""}
        model={customer}
        onUpdate={handleUpdate}
        onBeforeSave={onBeforeSave}
      >
        <PageHeader
          icon="user"
          title={
            <>
              <FormattedMessage id="app.customer" />: {renderedCustomerTitle}
            </>
          }
          breadcrumb={[
            { text: <FormattedMessage id="app.list" />, link: "/customers" },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[
            {
              visible:
                activeItem === "factor" || activeItem === "distrigoLoan"
                  ? userHaveLdcUiCustomerEditRole &&
                    userHaveLdcCustomerManagementViewRole
                  : userHaveLdcUiCustomerEditRole,
              icon: editMode ? "lock" : "edit",
              content: editMode ? (
                "Wył. edycję"
              ) : (
                <FormattedMessage id="app.button.edit" />
              ),
              onClick: toggleEditMode,
              primary: true,
            },
          ]}
          refreshAction={refreshResources}
          loading={loading}
        />
        <Container fluid>
          {userHaveLdcUiCustomerEditRole && (
            <Dropdown
              trigger={<FormattedMessage id="app.locks" />}
              button
              simple
              basic
            >
              {blockMenuRendered}
            </Dropdown>
          )}
          {userHaveLdcOrderViewRole && (
            <>
              <Button
                content={<FormattedMessage id="app.dropdown.createOrder" />}
                basic
                as={Link}
                to={`/order/create?cId=${
                  customer && customer.rrdi !== null
                    ? customer.rrdi
                    : customer && customer.clientId
                }&dId=${customer && customer.clientId !== null ? customer.rrdi : ""}`}
              />
              <Button
                content={<FormattedMessage id="app.dropdown.orderList" />}
                basic
                as={Link}
                to={`/orders?customer.rrid=${
                  customer && customer.clientId
                }&deliveryCustomer.rrid=${customer && customer.rrdi}`}
              />
            </>
          )}
          {userHaveLdcClaimsViewRole && (
            <Button
              content={<FormattedMessage id="app.dropdown.claimsList" />}
              basic
              as={Link}
              to={`/claims?orderCustomerID=${
                customer && customer.clientId !== null ? customer.rrdi : ""
              }&accountingCustomerID=${
                customer && customer.clientId !== null
                  ? customer.clientId
                  : customer && customer.rrdi
              }`}
            />
          )}
          {userHaveLdcAccountingViewRole && (
            <Button
              content={<FormattedMessage id="app.dropdown.accountingDocs" />}
              basic
              as={Link}
              to={`/accounting?accountingCustomer.taxCode=${customer && customer.accountingVatId}`}
            />
          )}
        </Container>
        <TabSwitcher
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          tabs={[
            { name: "main", title: formatMessage("app.customer.main") },
            { name: "address", title: formatMessage("app.address") },
            { name: "logistic", title: formatMessage("app.customer.logistic") },
            { name: "payments", title: formatMessage("app.customer.payments") },
            {
              name: "changePayer",
              title: formatMessage("app.customer.changePayer"),
            },
            {
              name: "bookkeeping",
              title: formatMessage("app.customer.accounting"),
            },
            { name: "factor", title: formatMessage("app.customer.factor") },
            {
              name: "distrigoLoan",
              title: formatMessage("app.customer.distrigoLoan"),
            },
            { name: "gdpr", title: formatMessage("app.customer.gpdr") },
            { name: "other", title: formatMessage("app.customer.others") },
            { name: "all", title: formatMessage("app.customer.all") },
          ]}
          onTabChange={setVisibleCards}
          categoryType={customer && customer.categoryType}
          customerType={customer?.customerType}
          userHaveLdcCustomerPayerEditRole={userHaveLdcCustomerPayerEditRole}
        />
        {cardsRendered}
      </SmartWrapper>
      <CommonLoader loading={loading || !customer} />
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  customers,
  dictionaries,
  factor,
  distrigoCredit,
  paymentMethods,
}: ApplicationState) => {
  return {
    customerIn: customers.selected && customers.selected.detail,
    loading:
      customers.loading ||
      dictionaries.loading ||
      customers.loadingAddNewPayer ||
      customers.loadingCurrentPayer ||
      customers.loadingPayerHistoryList,
    dictionaries,
    factors: factor.customerPaymentFactors,
    creditLoading: distrigoCredit.creating || distrigoCredit.loading,
    creditCreated: distrigoCredit.created,
    createdFactorId: factor.createdFactorId,
    paymentMethods: paymentMethods.customerPaymentMethods,
    currentPayer: customers.currentPayer,
    payerHistoryList: customers.payerHistoryList,
    classifications: customers.classifications,
  };
};

export default connect(mapStateToProps)(CustomerDetails);
