import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TColumn } from "~/components/Table/lib/types";
import { IInvoice } from "~/store/invoices/types";
import { connect } from "react-redux";
import { Divider, Table } from "semantic-ui-react";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { ApplicationState } from "~/store";
import { DictionariesState } from "~/store/dictionaries/types";
import {
  fetchInvoices,
  getInvoicePdf,
  retrySent,
  openChooseTypeOfExportDataModal,
} from "~/store/invoices/actions";
import { TotalRecords, PaginationMeta } from "~/store/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { useInvoicesColumns } from "./useInvoicesColumns";
import { InvoicesDropdown } from "./InvoicesDropdown";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useInvoicesAdvancedSearch } from "./useInvoicesAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";

type TComponentProps = {
  refreshTrigger: number;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
};

type TReduxState = {
  invoices: IInvoice[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
  selectedTypeOfDataToExport?: string;
};

type TReduxActions = {
  fetchInvoices: typeof fetchInvoices;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  retrySent: typeof retrySent;
};

type InvoicesProps = TComponentProps & TReduxState & TReduxActions;

const Invoices: React.FC<InvoicesProps> = ({
  fetchInvoices,
  getInvoicePdf,
  invoices,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport,
  openChooseTypeOfExportDataModal,
  setDisableExportButton,
  setPercenExportButton,
  retrySent,
}) => {
  const { columnsConfig, columnsConfigInvoicesWithInvoiceLine } =
    useInvoicesColumns();
  const [tableKey] = useState<string>("accounting/invoices");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("invoiceDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [columnsWithInvoiceLine, setColumnsWithInvoiceLine] = useState<
    TColumn[]
  >(columnsConfigInvoicesWithInvoiceLine);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [initiated, setInitiated] = useState(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport,
    columns,
    columnsWithInvoiceLine,
    fileName: "Faktury",
  });

  const getInvoices = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInvoices(Object.assign(paramsObj, params));
    },
    [
      setParams,
      fetchInvoices,
      filterLabelsRef,
      meta.page,
      meta.size,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
    setTimeout(() => setInitiated(true), 100);
  }, [params]);

  const completeColumnDictionaries = useCallback(
    (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      ),
    [dictionaries]
  );

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
    setColumnsWithInvoiceLine((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getInvoices({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getInvoices]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  const { formFields, handleSubmit } = useInvoicesAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getInvoices,
    filterLabelsRef,
    tableKey
  );

  return initiated ? (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getInvoices}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {invoices.map((document: IInvoice, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <InvoicesDropdown
                createdHandle={getInvoices}
                document={document}
                getInvoice={getInvoicePdf}
                retrySent={retrySent}
              ></InvoicesDropdown>
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  ) : (
    <></>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  invoices: invoices.list,
  loading: invoices.loadingInvoices,
  totalRecords: invoices.totalRecords,
  meta: invoices.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchInvoices,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
  retrySent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
