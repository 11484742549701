import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { ApplicationState } from "~/store";
import { fetchAnomalies } from "~/store/anomalies/actions";
import { Anomaly } from "~/store/anomalies/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import translations from "~/utils/translations";
import AnomaliesList from "./List";
import { useAnomaliesColumns } from "./hooks/useAnomaliesColumns";

export interface PropsFromState {
  loading: boolean;
  anomalies: Anomaly[];
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  totalRecords: TotalRecords;
}

export interface PropsFromDispatch {
  fetchAnomalies: typeof fetchAnomalies;
  fetchDictionary: typeof fetchDictionary;
}

export type AnomaliesProps = PropsFromState & PropsFromDispatch;

const Anomalies: React.FC<AnomaliesProps> = ({
  loading,
  anomalies,
  meta,
  dictionaries,
  totalRecords,
  fetchAnomalies,
  fetchDictionary,
}) => {
  const { columnsConfig } = useAnomaliesColumns();
  const [tableKey] = useState<string>("anomalies");
  const [filterLabels] = useState<any[]>([]);
  const [sortColumn] = useState<any>("date");
  const [sortDirection] = useState<any>("DESC");
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<Object>({});

  const getAnomalies = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabels,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchAnomalies(Object.assign(paramsObj, params));
    },
    [
      fetchAnomalies,
      meta.page,
      meta.size,
      filterLabels,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    document.title = translations.format("LDC_ANOMALY");
    fetchDictionary(DictionaryName.anomalyType, DictionaryName.anomalyStatus);
  }, [fetchDictionary]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  return (
    <Fragment>
      <PageHeader
        icon="exclamation circle"
        title="Anomalie magazynowe"
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[]}
        refreshAction={() => {
          getAnomalies({ ...params, page: 1 });
        }}
        loading={loading}
      />
      <DataGridTable
        columns={columns}
        fetchMethod={getAnomalies}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        loading={loading}
        tableKey={tableKey}
        meta={meta}
        totalRecords={totalRecords}
        setColumns={setColumns}
        dictionaries={dictionaries}
        isAdvancedSearchOpen={false}
      >
        <AnomaliesList anomalies={anomalies} columns={columns} />
      </DataGridTable>
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps = ({ anomalies, dictionaries }: ApplicationState) => ({
  anomalies: anomalies.list,
  meta: anomalies.meta,
  loading: anomalies.loadingAnomalies,
  totalRecords: anomalies.totalRecords,
  dictionaries,
});

const mapDispatchToProps = {
  fetchAnomalies,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Anomalies);
