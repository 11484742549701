import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchInvoicesErp,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { IInvoiceErp } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import AddInvoiceTransport from "../../lib/AddInvoiceTransport";
import { useInvoicesErpColumns } from "./useInvoicesErpColumns";
import InvoiceErpDetails from "~/pages/Accounting/Details/InvoiceErp";
import IconHover from "~/components/IconHover/IconHover";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useInvErpAdvancedSearch } from "./useInvErpAdvancedSearch";

type TComponentProps = {
  refreshTrigger: number;
  openAddTransportFVModal: boolean;
  setOpenAddTransportFVModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
};

type TReduxState = {
  invoicesErp: IInvoiceErp[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
};

interface TReduxActions {
  fetchInvoicesErp: typeof fetchInvoicesErp;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
}

type InvoicesErpProps = TComponentProps & TReduxState & TReduxActions;

const InvoicesErp: React.FC<InvoicesErpProps> = ({
  fetchInvoicesErp,
  invoicesErp,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  openAddTransportFVModal,
  setOpenAddTransportFVModal,
  openChooseTypeOfExportDataModal,
  setDisableExportButton,
  setPercenExportButton,
}) => {
  const { columnsConfig } = useInvoicesErpColumns();
  const [tableKey] = useState<string>("invoices/erp");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("invoiceDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport: undefined,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "Dokumenty_zrodlowe",
  });

  const getInvoicesErp = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInvoicesErp(Object.assign(paramsObj, params));
    },
    [
      fetchInvoicesErp,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getInvoicesErp({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getInvoicesErp]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  useEffect(() => {
    if (isOpenChooseTypeOfExportDataModal) {
      handleOpenDownloadModal();
      openChooseTypeOfExportDataModal(false);
    }
  }, [
    isOpenChooseTypeOfExportDataModal,
    handleOpenDownloadModal,
    openChooseTypeOfExportDataModal,
  ]);

  const createdHandle = useCallback(() => {
    getInvoicesErp();
    setOpenAddTransportFVModal(false);
  }, [getInvoicesErp, setOpenAddTransportFVModal]);

  const { formFields, handleSubmit } = useInvErpAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getInvoicesErp,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getInvoicesErp}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {invoicesErp.map((document: IInvoiceErp, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <InvoiceErpDetails noteId={document.id} closeHandler={() => {}}>
                <div role="button">
                  <IconHover name="arrow right" title="Podgląd" />
                </div>
              </InvoiceErpDetails>
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
      <AddInvoiceTransport
        triggerOpen={openAddTransportFVModal}
        isDebitNote={false}
        createdSuccess={createdHandle}
        setOpenAddTransportFVModal={setOpenAddTransportFVModal}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  invoicesErp: invoices.invoicesErp.list,
  loading: invoices.invoicesErp.loading,
  totalRecords: invoices.invoicesErp.totalRecords,
  meta: invoices.invoicesErp.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchInvoicesErp,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesErp);
