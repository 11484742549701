import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState } from "~/store";
import { createPromotion, fetchPromotions } from "~/store/promotions/actions";
import { TPromotion } from "~/store/promotions/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { usePromotionsButtons } from "./hooks/usePromotionsButtons";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { DataGridTable } from "~/components/Table/DataGridTable";
import PromotionsList from "./List";
import translations from "~/utils/translations";
import { usePromotionsColumns } from "./hooks/usePromotionsColumns";

type TReduxState = {
  promotions: TPromotion[];
  loading: boolean;
  meta: PaginationMeta;
  totalRecords: TotalRecords;
  created?: string;
};

type TReduxActions = {
  fetchPromotions: typeof fetchPromotions;
  createPromotion: typeof createPromotion;
};

type TProps = TReduxState & TReduxActions & RouteComponentProps<{ id: string }>;

const Promotions: React.FC<TProps> = ({
  promotions,
  loading,
  meta,
  totalRecords,
  created,
  fetchPromotions,
  createPromotion,
  history,
}) => {
  const { columnsConfig } = usePromotionsColumns();
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("promotions");
  const [sortColumn] = useState<any>();
  const [sortDirection] = useState<any>();
  const [columns, setColumns] = useState(columnsConfig);
  const [params, setParams] = useState<Object>({});

  const getPromotions = (params?: Object): void => {
    const paramsObj = {
      page: meta.page,
      size: meta.size,
      filters: filterLabelsRef.current,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };
    setParams(paramsObj);
    fetchPromotions(Object.assign(paramsObj, params));
  };

  const handleAddPromotion = useCallback(() => {
    createPromotion();
  }, [createPromotion]);

  const handleImportPromotion = useCallback((): void => {
    history.push("/promotion/import-promotion");
  }, [history]);

  const buttons = usePromotionsButtons({
    handleAddPromotion,
    handleImportPromotion,
  });

  useEffect(() => {
    document.title = translations.format("app.promotions");
  }, []);

  useEffect(() => {
    if (created) {
      history.push(`/promotion/${created}/edit`);
    }
  }, [created, history]);

  return (
    <Fragment>
      <PageHeader
        icon="star"
        title={<FormattedMessage id="app.promotions" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        refreshAction={() => {
          getPromotions({ ...params, page: 1 });
        }}
        loading={loading}
      />
      <DataGridTable
        columns={columns}
        setColumns={setColumns}
        fetchMethod={getPromotions}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        isAdvancedSearchOpen={false}
        loading={loading}
        tableKey={tableKey}
        meta={meta}
        totalRecords={totalRecords}
      >
        <PromotionsList columns={columns} list={promotions} />
      </DataGridTable>
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  promotions,
}: ApplicationState) => {
  return {
    promotions: promotions.list,
    meta: promotions.meta,
    loading: promotions.loading,
    totalRecords: promotions.totalRecords,
    created: promotions.created,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchPromotions,
  createPromotion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Promotions));
