import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Divider } from "semantic-ui-react";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import {
  changeStatusOrder,
  fetchOrders,
  openChooseTypeOfExportDataModal,
  saveDataFromSearchForm,
  saveDataToSearchForm,
  saveReferenceIdSearchForm,
  setTypeOfExportData,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  uploadCancelLines,
  uploadCancelLinesClear,
} from "~/store/orders/actions";
import { Order, OrderBonusImportLine } from "~/store/orders/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import OrdersList from "./List";
import translations from "~/utils/translations";
import { useOrderButtons } from "./hooks/useOrdersButtons";
import { DataGridTable } from "~/components/Table/DataGridTable";
import ChooseDownloadOptionModal from "../Accounting/lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "./hooks/useHandleDownloadModal";
import { UploadBonusOrdersModal, UploadLinesToCancel } from "./lib/Modals";
import CancelOrder from "./lib/CancelOrder/CancelOrder";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useOrdersAdvancedSearch } from "./hooks/useOrdersAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useOrdersColumns } from "./hooks/useOrdersColumns";
import { useOrdersRolesRights } from "./hooks/useOrdersRolesRights";

interface PropsFromState {
  orders: Order[];
  loading: boolean;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  orderBonusLines: OrderBonusImportLine[];
  loadingBonusOrder: boolean;
  loadingCancelLines: boolean;
  totalRecords: TotalRecords;
  selectedTypeOfDataToExport?: string;
  isOpenChooseTypeOfExportDataModal?: boolean;
}

interface PropsFromDispatch {
  fetchOrders: typeof fetchOrders;
  changeStatusOrder: typeof changeStatusOrder;
  fetchDictionary: typeof fetchDictionary;
  saveReferenceIdSearchForm: typeof saveReferenceIdSearchForm;
  saveDataFromSearchForm: typeof saveDataFromSearchForm;
  saveDataToSearchForm: typeof saveDataToSearchForm;
  uploadBonusOrders: typeof uploadBonusOrders;
  uploadBonusOrdersClear: typeof uploadBonusOrdersClear;
  uploadCancelLines: typeof uploadCancelLines;
  uploadCancelLinesClear: typeof uploadCancelLinesClear;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  setTypeOfExportData: typeof setTypeOfExportData;
}

type OrderProps = PropsFromState &
  PropsFromDispatch &
  ConnectedReduxProps &
  RouteComponentProps;

export const Orders: React.FC<OrderProps> = ({
  dictionaries,
  isOpenChooseTypeOfExportDataModal,
  loading,
  loadingBonusOrder,
  loadingCancelLines,
  meta,
  orders,
  orderBonusLines,
  selectedTypeOfDataToExport,
  totalRecords,
  changeStatusOrder,
  fetchOrders,
  fetchDictionary,
  openChooseTypeOfExportDataModal,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  uploadCancelLines,
  uploadCancelLinesClear,
}) => {
  const { columnsConfig, columnsWithInvoiceLineConfig } = useOrdersColumns();

  const [isOpen, setIsOpen] = useState(false);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("orders");
  const [sortColumn] = useState<any>("orderDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [openCancelOrderPage, setOpenCancelOrderPage] =
    useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openCancelLinesModal, setOpenCancelLinesModal] =
    useState<boolean>(false);
  const [columns, setColumns] = useState(columnsConfig);
  const [columnsWithInvoiceLine, setColumnsWithInvoiceLine] = useState(
    columnsWithInvoiceLineConfig
  );
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const [initiated, setInitiated] = useState(false);

  const { userHaveLdcUiOrderCloseUnrealizedRole } = useOrdersRolesRights();

  const [
    cancelButton,
    addButton,
    downloadButton,
    uploadButton,
    linesUploadButton,
  ] = useOrderButtons({
    setOpenCancelOrderPage,
    disableButton,
    exportPercent,
    openChooseTypeOfExportDataModal,
    setOpenUploadModal,
    setOpenCancelLinesModal,
  });

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport,
    columns,
    columnsWithInvoiceLine,
  });

  const getOrders = useCallback(
    (items?: Object) => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };

      setParams(paramsObj);
      fetchOrders(Object.assign(paramsObj, items));
    },
    [
      filterLabelsRef,
      setParams,
      fetchOrders,
      meta.page,
      meta.size,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
    setTimeout(() => setInitiated(true), 100);
  }, [params]);

  const { formFields, handleSubmit } = useOrdersAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getOrders,
    filterLabelsRef,
    tableKey
  );

  useEffect(() => {
    document.title = translations.format("app.orders");
    fetchDictionary(
      DictionaryName.orderDeliveryType,
      DictionaryName.paymentMethod,
      DictionaryName.orderType,
      DictionaryName.orderStatus
    );
  }, [fetchDictionary]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
    setColumnsWithInvoiceLine((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  const handleCloseAndClearUploadModal = (): void => {
    setOpenUploadModal(false);
    getOrders(params);
    uploadBonusOrdersClear();
  };

  const handleCloseAndClearCancelLinesModal = (): void => {
    setOpenCancelLinesModal(false);
    getOrders(params);
    uploadCancelLinesClear();
  };

  return initiated ? (
    <Fragment>
      <PageHeader
        icon="shop"
        title={<FormattedMessage id="app.orders" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[
          cancelButton,
          addButton,
          downloadButton,
          uploadButton,
          linesUploadButton,
        ]}
        refreshAction={() => {
          getOrders({ ...params, page: 1 });
        }}
        loading={loading}
      />
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getOrders}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        <OrdersList
          orders={orders}
          changeStatusOrder={changeStatusOrder}
          columns={columns}
          cancelRole={userHaveLdcUiOrderCloseUnrealizedRole}
        />
      </DataGridTable>

      <UploadBonusOrdersModal
        open={openUploadModal}
        orderBonusLines={orderBonusLines}
        uploadBonusOrders={uploadBonusOrders}
        loading={loadingBonusOrder}
        closeHandler={handleCloseAndClearUploadModal}
      />
      <UploadLinesToCancel
        open={openCancelLinesModal}
        uploadCancelLines={uploadCancelLines}
        loading={loadingCancelLines}
        closeHandler={handleCloseAndClearCancelLinesModal}
      />
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
      {openCancelOrderPage && (
        <CancelOrder
          openCancelOrderModal={openCancelOrderPage}
          closeModal={() => setOpenCancelOrderPage(false)}
        />
      )}
    </Fragment>
  ) : (
    <></>
  );
};

const mapStateToProps = ({ orders, dictionaries }: ApplicationState) => ({
  orders: orders.list,
  orderBonusLines: orders.orderBonusLines,
  meta: orders.meta,
  loading: orders.loadingOrders,
  loadingBonusOrder: orders.loadingBonusOrder,
  loadingCancelLines: orders.loadingCancelLines,
  totalRecords: orders.totalRecords,
  dictionaries,
  selectedTypeOfDataToExport: orders.selectedTypeOfDataToExport,
  isOpenChooseTypeOfExportDataModal: orders.isOpenChooseTypeOfExportDataModal,
});

const mapDispatchToProps = {
  fetchOrders,
  changeStatusOrder,
  fetchDictionary,
  saveReferenceIdSearchForm,
  saveDataFromSearchForm,
  saveDataToSearchForm,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  uploadCancelLines,
  uploadCancelLinesClear,
  openChooseTypeOfExportDataModal,
  setTypeOfExportData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
