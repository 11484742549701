import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { fetchSpareParts } from "~/store/spare-parts/actions";
import { SparePart } from "~/store/spare-parts/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { useSparePartsButtons } from "./hooks/useSparePartsButtons";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useHandleDownloadModal } from "./hooks/useHandleDownloadModal";
import translations from "~/utils/translations";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import SparePartsList from "./List";
import ImportReferences from "./lib/ImportReferences";
import { useSparePartsColumns } from "./hooks/useSparePartsColumns";

type TReduxState = {
  spareParts: SparePart[];
  loading: boolean;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  totalRecords: TotalRecords;
};

type TReduxActions = {
  fetchSpareParts: typeof fetchSpareParts;
  fetchDictionary: typeof fetchDictionary;
};

type TProps = TReduxState & TReduxActions & ConnectedReduxProps;

const SpareParts: React.FC<TProps> = ({
  spareParts,
  loading,
  meta,
  dictionaries,
  totalRecords,
  fetchDictionary,
  fetchSpareParts,
}) => {
  const { columnsConfig } = useSparePartsColumns();
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("spare-parts");
  const [sortColumn] = useState<any>(undefined);
  const [sortDirection] = useState<any>(undefined);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [columns, setColumns] = useState(columnsConfig);
  const [params, setParams] = useState<Object>({});

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    columns,
  });

  const getProducts = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchSpareParts(Object.assign(paramsObj, params));
    },
    [fetchSpareParts, meta.page, meta.size, sortColumn, sortDirection]
  );

  const buttons = useSparePartsButtons({
    disableButton,
    exportPercent,
    setOpenImportModal,
    handleOpenDownloadModal,
  });

  useEffect(() => {
    document.title = translations.format("app.products");
    fetchDictionary(
      DictionaryName.sparePartCategoryAdv,
      DictionaryName.sparePartCategoryOrigin,
      DictionaryName.sparePartTaxCategory,
      DictionaryName.sparePartDiscountPcd,
      DictionaryName.sparePartDiscountIam,
      DictionaryName.sparePartDiscountFca,
      DictionaryName.sparePartState,
      DictionaryName.spareParReplacementCategory,
      DictionaryName.sparePartBrand,
      DictionaryName.sparePartHierarchyFamily,
      DictionaryName.sparePartHierarchySlot,
      DictionaryName.sparePartHierarchySegment,
      DictionaryName.sparePartHierarchyIndex,
      DictionaryName.sparePartProvisionCategory,
      DictionaryName.sparePartProvisionForceCategory
    );
  }, [fetchDictionary]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns(completeColumnDictionaries(columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries]);

  const handleImportSuccess = useCallback(() => {
    getProducts({ ...params, page: 1 });
  }, [getProducts, params]);

  return (
    <Fragment>
      <PageHeader
        icon="tag"
        title={<FormattedMessage id="app.products" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        refreshAction={() => {
          getProducts({ ...params, page: 1 });
        }}
        loading={loading}
      />
      <ImportReferences
        triggerOpen={openImportModal}
        importSuccess={handleImportSuccess}
        setOpenImportModal={setOpenImportModal}
      />
      <DataGridTable
        columns={columns}
        fetchMethod={getProducts}
        initSortColumn={undefined}
        initSortDirection={undefined}
        isAdvancedSearchOpen={false}
        loading={loading}
        setColumns={setColumns}
        tableKey={tableKey}
        dictionaries={dictionaries}
        meta={meta}
        totalRecords={totalRecords}
        customHeight="calc(95vh - 150px)"
      >
        <SparePartsList columns={columns} spareParts={spareParts} />
      </DataGridTable>
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps = ({ spareParts, dictionaries }: ApplicationState) => ({
  spareParts: spareParts.list,
  meta: spareParts.meta,
  loading: spareParts.loadingSpareParts || dictionaries.loading,
  totalRecords: spareParts.totalRecords,
  dictionaries,
});

const mapDispatchToProps = {
  fetchSpareParts,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpareParts);
