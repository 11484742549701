import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState } from "~/store";
import { fetchBackorders } from "~/store/backorder/actions";
import { Backorder } from "~/store/backorder/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { useBackordersButtons } from "./hooks/useBackordersButtons";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import AddBackorder from "./lib/Add";
import { DataGridTable } from "~/components/Table/DataGridTable";
import BackordersList from "./List";
import { TColumn } from "~/components/Table/lib/types";
import translations from "~/utils/translations";
import { useHandleDownloadModal } from "./hooks/useHandleDownloadModal";
import { useBackordersAdvancedSearch } from "./hooks/useBackordersAdvancedSearch";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useBackordersColumns } from "./hooks/useBackordersColumns";

type PropsFromState = {
  loading: boolean;
  backorders: Backorder[];
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  totalRecords: TotalRecords;
};

type PropsFromDispatch = {
  fetchBackorders: typeof fetchBackorders;
  fetchDictionary: typeof fetchDictionary;
};

type BackordersProps = PropsFromState & PropsFromDispatch;

const Backorders: React.FC<BackordersProps> = ({
  loading,
  backorders,
  meta,
  dictionaries,
  totalRecords,
  fetchBackorders,
  fetchDictionary,
}) => {
  const { columnsConfig } = useBackordersColumns();
  const [isOpen, setIsOpen] = useState(false);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("backorders");
  const [sortColumn] = useState<any>("creationTime");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [columns, setColumns] = useState(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const getBackorders = useCallback(
    (params?: Object): void => {
      let mappedParams: Object = {};
      if (params) {
        mappedParams = { ...params };
        if ("filters" in mappedParams) {
          const filtersToMap = mappedParams.filters as TTableFilterSession[];
          mappedParams.filters = filtersToMap.map((item) => {
            if (item.name === "batchId" && item.value === "n/d") {
              return { ...item, value: null };
            } else {
              return item;
            }
          });
        }
      }
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchBackorders(Object.assign(paramsObj, mappedParams));
    },
    [fetchBackorders, meta.page, meta.size, sortColumn, sortDirection]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { formFields, handleSubmit } = useBackordersAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getBackorders,
    filterLabelsRef,
    tableKey
  );

  useEffect(() => {
    document.title = translations.format("LDC_BACKORDER");
    fetchDictionary(DictionaryName.backorderState);
  }, [fetchDictionary]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    columns: columns,
    columnsWithInvoiceLine: columns,
    exportPercent,
    fileName: "Zamowienia_hurtowe",
    params,
    selectedTypeOfDataToExport: undefined,
    setDisableButton,
    setExportPercent,
    tableKey,
    totalRecords,
  });

  const buttons = useBackordersButtons({
    disableButton,
    exportPercent,
    setOpenCreateModal,
    handleOpenDownloadModal,
  });

  const createdHandle = useCallback(() => {
    getBackorders(params);
    setOpenCreateModal(false);
    // eslint-disable-next-line
  }, [params]);

  return (
    <Fragment>
      <AddBackorder
        triggerOpen={openCreateModal}
        createdSuccess={createdHandle}
        setOpenCreateModal={setOpenCreateModal}
      />
      <PageHeader
        icon="shop"
        title="Zamówienia hurtowe"
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        refreshAction={() => {
          getBackorders({ ...params, page: 1 });
        }}
        loading={loading}
      />

      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        fetchMethod={getBackorders}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        isAdvancedSearchOpen={isOpen}
        loading={loading}
        setColumns={setColumns}
        tableKey={tableKey}
        dictionaries={dictionaries}
        meta={meta}
        totalRecords={totalRecords}
      >
        {/* TODO: BackorderList cells rendere to refactor */}
        <BackordersList backorders={backorders} columns={columns} />
      </DataGridTable>
      <CommonLoader loading={loading} />
    </Fragment>
  );
};

const mapStateToProps = ({ backorders, dictionaries }: ApplicationState) => ({
  backorders: backorders.list,
  loading: backorders.loadingBackorders || dictionaries.loading,
  meta: backorders.meta,
  totalRecords: backorders.totalRecords,
  dictionaries,
});

const mapDispatchToProps = {
  fetchBackorders,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Backorders);
