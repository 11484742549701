import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  clearCollectiveCorrections,
  confirmCollectiveCorrectionsLines,
  fetchCollectiveCorrections,
  getCollectiveCorrectionPdf,
  openChooseTypeOfExportDataModal,
  previewCollectiveCorrectionPdf,
  uploadCollectiveCorrections,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { ICollectiveCorrectionsList } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { CollectiveCorrectionsModal } from "../../lib/Corrections/lib/CollectiveCorrectionsModal";
import IconHover from "~/components/IconHover/IconHover";
import { useCollectiveInvoiceCorrectionsColumns } from "./useCollectiveInvoiceCorrectionsColumns";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useCollectiveInvoiceCorrectionsAdvancedSearch } from "./useCollectiveInvoiceCorrectionsAdvancedSearch";

type TComponentProps = {
  refreshTrigger: number;
  openBulkCorrectionsModal: boolean;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
  setOpenBulkCorrectionsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type TReduxState = {
  debitNotesCorrections: ICollectiveCorrectionsList[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
  selectedTypeOfDataToExport?: string;
  createdCollectiveCorretion?: boolean;
  loadingConfirmCollectiveCorrections: boolean;
  loadingCollectiveCorrections: boolean;
  collectiveCorrections?: any[];
  collectiveCorrectionsErrorBody: any;
};

interface TReduxActions {
  fetchCollectiveCorrections: typeof fetchCollectiveCorrections;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  previewCollectiveCorrectionPdf: typeof previewCollectiveCorrectionPdf;
  confirmCollectiveCorrectionsLines: typeof confirmCollectiveCorrectionsLines;
  clearCollectiveCorrections: typeof clearCollectiveCorrections;
  uploadCollectiveCorrections: typeof uploadCollectiveCorrections;
  getCollectiveCorrectionPdf: typeof getCollectiveCorrectionPdf;
}

type DebitNotesCorrectionsProps = TComponentProps & TReduxState & TReduxActions;

const CollectiveInvoiceCorrections: React.FC<DebitNotesCorrectionsProps> = ({
  fetchCollectiveCorrections,
  debitNotesCorrections,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport,
  loadingConfirmCollectiveCorrections,
  createdCollectiveCorretion,
  loadingCollectiveCorrections,
  collectiveCorrections,
  openBulkCorrectionsModal,
  collectiveCorrectionsErrorBody,
  setOpenBulkCorrectionsModal,
  setDisableExportButton,
  setPercenExportButton,
  previewCollectiveCorrectionPdf,
  confirmCollectiveCorrectionsLines,
  clearCollectiveCorrections,
  uploadCollectiveCorrections,
  getCollectiveCorrectionPdf,
}) => {
  const { columnsConfig } = useCollectiveInvoiceCorrectionsColumns();
  const [tableKey] = useState<string>(
    "accounting/collective-corrections/collective-invoice-corrections"
  );
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("createDateTime");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "Korekty_zbiorcze_FV",
  });

  const getDebitNotesCorretions = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchCollectiveCorrections(Object.assign(paramsObj, params));
    },
    [
      fetchCollectiveCorrections,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getDebitNotesCorretions({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getDebitNotesCorretions]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  const handleCreateSuccess = () => {
    getDebitNotesCorretions({ ...params });
    setOpenBulkCorrectionsModal(false);
  };

  const { formFields, handleSubmit } =
    useCollectiveInvoiceCorrectionsAdvancedSearch(
      columns,
      setColumns,
      sortColumn,
      sortDirection,
      getDebitNotesCorretions,
      filterLabelsRef,
      tableKey
    );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getDebitNotesCorretions}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {debitNotesCorrections.map(
          (document: ICollectiveCorrectionsList, index: number) => (
            <Table.Row disabled={!!document.cancellationReason} key={index}>
              {columns &&
                columns.length &&
                columns.map(
                  (column) =>
                    column.getCell &&
                    column.projection &&
                    column.getCell(document, column)
                )}
              <Table.Cell className="col-dropdown-menu-sticky">
                <IconHover
                  name="file"
                  title="Pobierz"
                  onClick={() =>
                    getCollectiveCorrectionPdf(
                      document.id,
                      `${document.documentMask}.pdf`
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        )}
      </DataGridTable>
      <CollectiveCorrectionsModal
        created={!!createdCollectiveCorretion}
        previewCollectiveCorrectionPdf={previewCollectiveCorrectionPdf}
        loadingConfirmCollectiveCorrections={
          loadingConfirmCollectiveCorrections
        }
        loadingCollectiveCorrections={loadingCollectiveCorrections}
        confirmCollectiveCorrectionsLines={confirmCollectiveCorrectionsLines}
        clearCollectiveCorrections={clearCollectiveCorrections}
        uploadCollectiveCorrections={uploadCollectiveCorrections}
        correctionLines={collectiveCorrections || []}
        open={openBulkCorrectionsModal}
        uploadingError={collectiveCorrectionsErrorBody}
        closeHandler={handleCreateSuccess}
      />
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  debitNotesCorrections: invoices.collectiveCorrectionsList.list,
  loading: invoices.loadingCollectiveCorretions,
  loadingConfirmCollectiveCorrections:
    invoices.loadingConfirmCollectiveCorrections,
  totalRecords: invoices.collectiveCorrectionsList.totalRecords,
  meta: invoices.collectiveCorrectionsList.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
  createdCollectiveCorretion: invoices.createdCollectiveCorretion,
  loadingCollectiveCorrections: invoices.loadingCollectiveCorrections,
  collectiveCorrections: invoices.collectiveCorrections,
  collectiveCorrectionsErrorBody: invoices.collectiveCorrectionsErrorBody,
});

const mapDispatchToProps = {
  fetchCollectiveCorrections,
  openChooseTypeOfExportDataModal,
  previewCollectiveCorrectionPdf,
  confirmCollectiveCorrectionsLines,
  clearCollectiveCorrections,
  uploadCollectiveCorrections,
  getCollectiveCorrectionPdf,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectiveInvoiceCorrections);
