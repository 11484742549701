import React, { Fragment, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import PageHeader from "~/components/PageHeader/PageHeader";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { ApplicationState } from "~/store";
import {
  clearImportCustomers,
  fetchClassesAndBrands,
  fetchCustomers,
  importCustomersAdd,
  importCustomersUpdate,
  preImportCustomers,
} from "~/store/customer/actions";
import { ClassAndBrands, Customer, TPreImport } from "~/store/customer/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import CustomersList from "./List";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { useCustomersButtons } from "./hooks/useCustomersButtons";
import { useHandleDownloadModal } from "./hooks/useHandleDownloadModal";
import translations from "~/utils/translations";
import { TColumn } from "~/components/Table/lib/types";
import AddContractor from "./lib/AddContractor";
import { ImportCustomersModal } from "./lib/Import";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useCustomersAdvancedSearch } from "./hooks/useCustomersAdvancedSearch";
import { useCustomersColumns } from "./hooks/useCustomersColumns";
import { fetchDictionary } from "~/store/dictionaries/actions";

type PropsFromState = {
  customers: Customer[];
  meta: PaginationMeta;
  importedAdd: boolean;
  importedUpdate: boolean;
  loading: boolean;
  loadingPreImport: boolean;
  loadingImportAdd: boolean;
  loadingImportUpdate: boolean;
  totalRecords: TotalRecords;
  preImportResults?: TPreImport;
  showCustomerDetails(): string;
  customerClasses?: ClassAndBrands[];
  dictionaries: DictionariesState;
};

type PropsFromDispatch = {
  fetchCustomers: typeof fetchCustomers;
  preImportCustomers: typeof preImportCustomers;
  importCustomersAdd: typeof importCustomersAdd;
  importCustomersUpdate: typeof importCustomersUpdate;
  clearImportCustomers: typeof clearImportCustomers;
  fetchClassesAndBrands: typeof fetchClassesAndBrands;
  fetchDictionary: typeof fetchDictionary;
};

type CustomersProps = PropsFromState & PropsFromDispatch;

const Customers: React.FC<CustomersProps> = ({
  customers,
  meta,
  importedAdd,
  importedUpdate,
  loading,
  loadingPreImport,
  loadingImportAdd,
  loadingImportUpdate,
  totalRecords,
  preImportResults,
  dictionaries,
  fetchCustomers,
  preImportCustomers,
  importCustomersAdd,
  importCustomersUpdate,
  clearImportCustomers,
  fetchClassesAndBrands,
  fetchDictionary,
}) => {
  const { columnsConfig } = useCustomersColumns();
  const [isOpen, setIsOpen] = useState(false);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("customers");
  const [sortColumn] = useState<any>();
  const [sortDirection] = useState<any>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);
  const [columns, setColumns] = useState(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    columns,
  });
  const { addButton, downloadButton, uploadButton } = useCustomersButtons({
    disableButton,
    exportPercent,
    handleOpenDownloadModal,
    setOpenUploadModal,
    setOpenAddCustomer,
  });

  const getCustomers = (items?: Object): void => {
    const paramsObj = {
      page: meta.page,
      size: meta.size,
      filters: filterLabelsRef.current,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };
    setParams(paramsObj);
    fetchCustomers(Object.assign(paramsObj, items));
  };

  useEffect(() => {
    document.title = translations.format("app.customers");
    fetchDictionary(DictionaryName.paymentMethod);
  }, [fetchDictionary]);

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const handleCloseImportCustomerModal = (): void => {
    getCustomers();
    setOpenUploadModal(false);
  };

  const { formFields, handleSubmit } = useCustomersAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getCustomers,
    filterLabelsRef,
    tableKey
  );

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
              label: translations.format(column.label),
            }
          : { ...column, label: translations.format(column.label) }
      );
    setColumns(completeColumnDictionaries(columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries]);

  useEffect(() => {
    fetchClassesAndBrands();
  }, [fetchClassesAndBrands]);

  return (
    <Fragment>
      <PageHeader
        icon="users"
        title={<FormattedMessage id="app.customers" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[addButton, downloadButton, uploadButton]}
        refreshAction={() => {
          getCustomers({ ...params, page: 1 });
        }}
        loading={loading}
      />

      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getCustomers}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        <CustomersList customers={customers} columns={columns} />
      </DataGridTable>
      <AddContractor triggerOpen={openAddCustomer} />
      <ImportCustomersModal
        open={openUploadModal}
        importedAdd={importedAdd}
        importedUpdate={importedUpdate}
        loadingImportAdd={loadingImportAdd}
        loadingImportUpdate={loadingImportUpdate}
        preImportResults={preImportResults}
        loadingPreImport={loadingPreImport}
        importCustomersAdd={importCustomersAdd}
        importCustomersUpdate={importCustomersUpdate}
        preImportCustomers={preImportCustomers}
        clearImportCustomers={clearImportCustomers}
        closeHandler={() => handleCloseImportCustomerModal()}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ customers, dictionaries }: ApplicationState) => ({
  customers: customers.list,
  meta: customers.meta,
  preImportResults: customers.preImportResults,
  importedAdd: customers.importedAdd,
  importedUpdate: customers.importedUpdate,
  loading: customers.loading,
  loadingPreImport: customers.loadingPreImport,
  loadingImportAdd: customers.loadingImportAdd,
  loadingImportUpdate: customers.loadingImportUpdate,
  totalRecords: customers.totalRecords,
  customerClasses: customers.classesAndBrands,
  dictionaries,
});

const mapDispatchToProps = {
  fetchCustomers,
  preImportCustomers,
  importCustomersAdd,
  importCustomersUpdate,
  clearImportCustomers,
  fetchClassesAndBrands,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
