import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchInternalConsumptionNotes,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import {
  ICollectiveMM,
  IInternalConsumptionNote,
} from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { useInternalCompsumptionsColumns } from "./useInternalCompsumptionsColumns";
import InternalConsumptionNotesDetails from "~/pages/Accounting/Details/InternalConsumptionNotes";
import IconHover from "~/components/IconHover/IconHover";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useInternalCompsumtionAdvancedSearch } from "./useInternalConsumptionAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";

type TComponentProps = {
  refreshTrigger: number;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
};

type TReduxState = {
  internalConsumptionNotes: IInternalConsumptionNote[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
};

interface TReduxActions {
  fetchInternalConsumptionNotes: typeof fetchInternalConsumptionNotes;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
}

type InternalConsumtionsProps = TComponentProps & TReduxState & TReduxActions;

const InternalConsumptions: React.FC<InternalConsumtionsProps> = ({
  fetchInternalConsumptionNotes,
  internalConsumptionNotes,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  openChooseTypeOfExportDataModal,
  setDisableExportButton,
  setPercenExportButton,
}) => {
  const { columnsConfig } = useInternalCompsumptionsColumns();
  const [tableKey] = useState<string>("internal-consumptions");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("issueDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport: undefined,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "RW",
  });

  const getInternalCompsumtion = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInternalConsumptionNotes(Object.assign(paramsObj, params));
    },
    [
      fetchInternalConsumptionNotes,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getInternalCompsumtion({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getInternalCompsumtion]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  useEffect(() => {
    if (isOpenChooseTypeOfExportDataModal) {
      handleOpenDownloadModal();
      openChooseTypeOfExportDataModal(false);
    }
  }, [
    isOpenChooseTypeOfExportDataModal,
    handleOpenDownloadModal,
    openChooseTypeOfExportDataModal,
  ]);

  const { formFields, handleSubmit } = useInternalCompsumtionAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getInternalCompsumtion,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getInternalCompsumtion}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {internalConsumptionNotes.map(
          (document: ICollectiveMM, index: number) => (
            <Table.Row disabled={!!document.cancellationReason} key={index}>
              {columns &&
                columns.length &&
                columns.map(
                  (column) =>
                    column.getCell &&
                    column.projection &&
                    column.getCell(document, column)
                )}
              <Table.Cell className="col-dropdown-menu-sticky">
                <InternalConsumptionNotesDetails noteId={document.id || ""}>
                  <div role="button">
                    <IconHover name="arrow right" title="Podgląd" />
                  </div>
                </InternalConsumptionNotesDetails>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </DataGridTable>
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  internalConsumptionNotes: invoices.internalConsumptionNote.list,
  loading: invoices.internalConsumptionNote.loading,
  totalRecords: invoices.internalConsumptionNote.totalRecords,
  meta: invoices.internalConsumptionNote.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchInternalConsumptionNotes,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalConsumptions);
