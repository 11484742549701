import { TColumn } from "~/components/Table/lib/types";
import dictionariesStatic from "~/store/dictionaries/static";
import React, { useCallback, useMemo } from "react";
// import {
//   getDateCell,
//   getDictionaryCell,
//   getLinkCell,
//   getTextCell,
// } from "~/services/CellRenderer";
import { TableCell } from "semantic-ui-react";
import CodingCell from "~/pages/Backorders/Details/lib/CodingCell";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useManualOrdersColumns = () => {
  const { getDateCell, getDictionaryCell, getLinkCell, getTextCell } =
    useCellsRenderer();
  const getCodesCell = useCallback((name: string, part: any) => {
    return (
      <TableCell key={name + Math.random()}>
        <CodingCell
          line={part}
          loading={false}
          addCodingHandler={() => {}}
          editMode={false}
          disablePopup
          key={name}
        />
      </TableCell>
    );
  }, []);

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "orderNumber",
        label: "manual-orders.orderNumber",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(document, column, "/order/" + document.id),
      },
      {
        name: "lineNumber",
        label: "manual-orders.lineNumber",
        projection: true,
        filteringBlocked: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "referenceID",
        label: "manual-orders.reference",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "codes",
        label: "manual-orders.codes",
        projection: true,
        filteringBlocked: true,
        getCell: (document, column) => getCodesCell(column.name, document),
      },
      {
        name: "ldcOrderDate",
        label: "manual-orders.LDC-order-date",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "supplierOrderNumber",
        label: "manual-orders.CSPS-order-nr",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderedDate",
        label: "manual-orders.CSPS-order-date",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "orderedBy",
        label: "manual-orders.CSPS-order-user",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "businessSector",
        label: "table.supplierSystem.bussinesSystem",
        projection: true,
        dictionary: dictionariesStatic.SUPPLIER_SYSTEMS.filter(
          (item) => item.value
        ),
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "supplierSystemClientId",
        label:
          "accounting.invoices.column.orderCustomer.supplierSystem.clientId",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "supplierSystemDeliveryId",
        label: "app.table.supplierSystem.deliveryId",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "status",
        label: "manual-orders.realisation-status",
        projection: true,
        dictionary: dictionariesStatic.CODED_PART_STATUS,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
    ],
    [getCodesCell, getDateCell, getDictionaryCell, getLinkCell, getTextCell]
  );

  return {
    columnsConfig,
  };
};
